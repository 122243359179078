import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from '../src/Login';
import SubadminManagement from './pages/SubadminManagement';
import PushNotification from './pages/PushNotification';
import AddUserRole from './pages/AddUserRole';
import UpdateUserRole from './pages/UpdateUserRole';
import AssignRights from './pages/AssignRights';
import AddSubadmin from './pages/AddSubadmin';
import UpdateSubadmin from './pages/UpdateSubadmin';
import NFTCollectionManagement from './pages/NFTCollectionManagement';
import Collection from './pages/Collection';
import CollectionDetail from './pages/CollectionDetail';
import AddNewCollection from './pages/AddNewCollection';
import AddNewNFT from './pages/AddNewNFT';
import PartnerManagement from './pages/PartnerManagement';
import CouponManagment from './pages/CoupenManagment';
import AddNewPartner from './pages/AddNewPartner';
import AddNewCoupon from './pages/AddNewCoupon';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import ViewSubAdmin from './pages/ViewSubAdmin';
import ViewCoupon from './Components/ViewCoupon';
import Updatepartner from './pages/Updatepartner';
import { css } from '@emotion/react';
import BannerGeneration from './pages/BannerGeneration';
import RedemptionActivity from'./pages/RedemptionActivity'
import DiscountId from './pages/DiscountId';
import SignupTracking from './pages/SignupTracking';
import SendCoins from './Components/SendCoins';
import StoreLinks from './pages/StoreLinks';
import UserPoints from './Components/UserPoints';
import UserTransection from './Components/UserTransection';
import UpdateStore from './Components/UpdateStore';
import ProductSyncTable from './pages/ProductSyncTable';
import UpdateProduct from './pages/ProductSyncTable';

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem('Token');


  useEffect(() => {

    if (!token) {
      navigate('/');
    }

  }, [token])


  return (
    <>
        
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
        theme="light"
      />
      <Routes>

        <>
          <Route path="/SubadminManagement" element={<SubadminManagement />} />
          <Route path="/AddUserRole" element={<AddUserRole />} />
          <Route path="/UpdateUserRole" element={<UpdateUserRole />} />
          <Route path="/AssignRights" element={<AssignRights />} />
          <Route path="/AddSubadmin" element={<AddSubadmin />} />
          <Route path="/viewSubadmin/:id" element={<ViewSubAdmin />} />
          <Route path="/UpdateSubadmin/:id" element={<UpdateSubadmin />} />
          <Route path="/UpdatePartner/:id" element={<Updatepartner />} />
          <Route path="/NFTCollectionManagement" element={<NFTCollectionManagement />} />
          <Route path="/CollectionDetail/:id" element={<CollectionDetail />} />
          <Route path="/Collection/:id" element={<Collection />} />
          <Route path="/AddNewCollection" element={<AddNewCollection />} />
          <Route path="/updateCollection/:id" element={<AddNewCollection />} />
          <Route path="/AddNewNFT" element={<AddNewNFT />} />
          <Route path="/updateNft/:id" element={<AddNewNFT />} />
          <Route path="/PartnerManagement" element={<PartnerManagement />} />
          <Route path="/CouponManagment" element={<CouponManagment />} />
          <Route path="/SendNotification" element={<PushNotification />} />
          <Route path="/BannerGeneration" element={<BannerGeneration />} />
          <Route path="/RedemptionActivity" element={<RedemptionActivity />} />
          <Route path="/Coupon" element={<DiscountId />} />
          <Route path="/signupTracking" element={<SignupTracking />} />
          <Route path="/StoreLinks" element={<StoreLinks/>} />
          <Route path="/sendcoins" element={<SendCoins />} />
          <Route path="/Usertransection" element={<UserTransection/>} />
          <Route path="/UpdateStore" element={<UpdateStore/>} />
          <Route path="/UpdateProduct" element={<UpdateProduct/>} />
          <Route path="/UserPoints" element={<UserPoints />} />
          <Route path="/AddNewPartner" element={<AddNewPartner />} />
          <Route path="/AddNewCoupon" element={<AddNewCoupon />} />
          <Route path="/viewcoupon/:id" element={<ViewCoupon />} />
          <Route path="/updateCoupon/:id" element={<AddNewCoupon />} />
        </>

        <Route exact path="/" element={<Login />} />

      </Routes>
    </>
  );
}
export default App;
