import React, { useEffect, useState } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button, Row, Col, Modal } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import DropdownWithSearch from "../Components/DropdownWithSearch";
import {
  handleGetAllCollection,
  handleGetNftById,
  getAllAppUser,
  handleGetProductsCount,
} from "../shared/api";
import axios from "axios";
import { API_URL, Node_BE_URL, customStyles } from "../constant";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
import moment from "moment";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import CheckFileSize from "./controllers";
import InfiniteScrollList from "../Components/InfiniteScrollList";
import axiosApi from "../axiosApi";
import Logo from "../assets/images/small-logo.png";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
const tireTypeMappings = {
  1: "Purple",
  2: "Silver",
  3: "Gold",
  4: "Diamond",
  5: "Owner's Circle",
};
function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function CrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}
const distributionType = [
  {
    value: 2,
    name: "Product",
  },
  {
    value: 3,
    name: "Tier",
  },
  {
    value: 4,
    name: "promotion",
  },
];

const NFT_TYPE = {
  MarketPlace: 1,
  Product: 2,
  Tier: 3,
  Promotion: 4,
};

const NFT_TYPE_In_String = {
  MarketPlace: "MarketPlace",
  Product: "Product",
  Tier: "Tier",
  Promotion: "Promotion",
};

const tireNFTType = [
  {
    value: 1,
    name: "Purple",
  },
  {
    value: 2,
    name: "Silver",
  },
  {
    value: 3,
    name: "Gold",
  },
  {
    value: 4,
    name: "Diamond",
  },
  {
    value: 5,
    name: "OwnerCircle",
  },
];

function AddNewNFT() {
  const today = new Date().toISOString().slice(0, 10);
  const navigate = useNavigate();
  const location = useLocation();
  const [colID, setcolID] = useState();
  // const { id: colID, collectData } = location.state;
  const [nftUpload, setnftUpload] = useState(null);
  const [NFTActiveImage, setNFTActiveImage] = useState(null);
  const [NFTExpireImage, setNFTExpireImage] = useState(null);
  const [NFTUsedImage, setNFTUsedImage] = useState(null);
  const [nftType, setnftType] = useState(0);
  const [tireType, settireType] = useState(0);
  const [loader, setloader] = useState(false);
  const [collectoins, setcollectoins] = useState([]);
  const [show, setShow] = useState(false);
  const [prodcutListModal, setProdcutListModal] = useState(false);
  const [NFTpropertyList, setNFTpropertyList] = useState([]);
  const { id } = useParams();
  const [addPropertiesList, setAddPropertiesList] = useState([
    { type: "", rarity: "", type: "Currency" },
  ]);
  const [finalCreatedProperties, setFinalCreatedProperties] = useState([]);
  const [minCopies, setminCopies] = useState(0);
  const [tiresList, settiresList] = useState([]);
  const [options, setOptions] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [allLocation, setAllLocations] = useState([]);

  const [errorMessage, seterrorMessage] = useState({
    nameErr: "",
    imageErr: "",
    activeimageErr: "",
    expireimageErr: "",
    usedimageErr: "",
    expiryErr: "",
    priceErr: "",
    nftcopiesErr: "",
    descriptionErr: "",
    royaltyErr: "",
    collectionErr: "",
    productErr: "",
    discountCodeErr: "",
    calimCopyErr: "",
    store: null,
  });
  const [nftData, setnftData] = useState({
    nftName: "",
    image: "",
    activeimage: "",
    expireimage: "",
    usedimage: "",
    expirydate: "",
    price: "",
    nftcopies: "",
    calimCopy: "",
    locationid: "",
    discountCode: "",
    description: "",
    royalty: 0,
    collection: "",
    productList: "",
    NFTProductListid: null,
    HTNnumber: null,
  });
  const [Data, setdata] = useState({});
  const [productList, setproductList] = useState({});
  const Dropdown = ["Currency", "Token"];

  const [SelectedConmpany, setSelectedConmpany] = useState(null);
  const CompaniesLocation = [
    {
      id: 3854,
      name: "New Brunswick",
    },
    {
      id: 1154,
      name: "Ontario",
    },
    {
      id: 3501,
      name: "British Columbia",
    },
    {
      id: 3515,
      name: "Saskatchewan",
    },
    {
      id: 3643,
      name: "Albert",
    },
  ];

  useEffect(() => {
    if (id) {
      handleGetNftById(id)
        .then((res) => {
          const data = res.data?.data;
          setcolID(res.data?.data?.collectionId);
          setnftType(
            data?.distributionType == NFT_TYPE_In_String.Promotion
              ? NFT_TYPE.Promotion
              : data?.distributionType == "MarketPlace"
              ? NFT_TYPE.MarketPlace
              : data?.distributionType == "Product"
              ? NFT_TYPE.Product
              : data?.distributionType == NFT_TYPE_In_String.Tier &&
                NFT_TYPE.Tier
          );
          if (data?.distributionType === NFT_TYPE.Product) {
            // GetPropertiList()
            getPorductListCount();
          }
          setdata(data);
          const storeCopiesData = {};
          if (data.storeCopies && Array.isArray(data.storeCopies)) {
            data.storeCopies.forEach((store) => {
              storeCopiesData[store.locationId] = store.copies.toString();
            });
          }

          // Get the total nftcopies by summing all store copies
          const totalNftCopies =
            data.storeCopies && Array.isArray(data.storeCopies)
              ? data.storeCopies.reduce(
                  (sum, store) => sum + parseInt(store.copies || 0),
                  0
                )
              : data?.noOfCopy || 0;

          setnftData({
            ...nftData,
            nftName: data?.name,
            image: data?.image,
            price: data?.buyPrice,
            description: data?.description,
            royalty: data?.royalty,
            collection: data?.collectionId,
            expirydate: data?.expiryDate.split("T")[0],
            nftcopies: totalNftCopies, // Set the total NFT copies here
            discountCode: data?.discountCode,
            productList: data?.productId,
            HTNnumber: data?.gtin,
            NFTProductListid: data?.productId,
            NFTProductName: data?.productName,
            locationId: data?.locationId,
            companyId: data?.companyId,
            calimCopy: data?.calimCopy,
            storeCopies: storeCopiesData,
          });

          if (options?.length > 0 && data?.locationId) {
            const selectedStore = options?.find(
              (option) => option?.value == data.locationId
            );

            if (selectedStore) {
              setStores([selectedStore]);
            }
          }

          const tireType = data?.tierType;

          getAllAppUser()
            .then((res) => {
              const matchingTire = res.data?.data?.find(
                (tire) =>
                  tire.tireType === tireType ||
                  tire.tireType === "Owner's Circle"
              );
              if (matchingTire) {
                setminCopies(matchingTire.tireCount);
              }

              setloader(false);
            })
            .catch((error) => {
              toast.error("An error occured. Please try again.");
              setloader(false);
            });
          setAddPropertiesList([...data?.nftProperties]);
          data?.nftProperties?.map((item, index) => {
            // setAddPropertiesList((pre) => [
            //   ...pre,
            //   {
            //     type: item.type,
            //     name: item.name,
            //     rarity: item.rarity,
            //   },
            // ]);
            setFinalCreatedProperties((pre) => [
              ...pre,
              {
                type: item.type,
                name: item.name,
                rarity: item.rarity,
              },
            ]);
          });

          // for (let index = 1; index <= data?.nftProperties?.length; index++) {
          //     const element = data?.nftProperties[index];
          //     setAddPropertiesList([
          //         ...addPropertiesList,
          //         {
          //             type: element.type, name: element.name, rarity: element.rarity
          //         }
          //     ])
          //     setFinalCreatedProperties([
          //         ...finalCreatedProperties,
          //         {
          //             type: element.type, name: element.name, rarity: element.rarity
          //         }
          //     ])

          // }
        })
        .catch((erro) => {});
    } else {
      // GetPropertiList()
      getPorductListCount();
    }

    axios
      .get(API_URL + `api/Nft/GetApproveCollections`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setcollectoins(res.data?.data);
      })
      .catch((error) => {});

    axios
      .get(API_URL + `api/SuperAdmin/GetAllAppUser`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        settiresList(res.data?.data);
      })
      .catch((error) => {});
  }, [options]);

  const getPorductListCount = () => {
    setloader(true);

    axiosApi
      .get(API_URL + `api/Nft/GetProductsCount`)
      .then((res) => {
        setproductList((prev) => {
          return {
            ...prev,
            count: res?.data?.data,
          };
        });
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterrorMessage((prevState) => ({
        ...prevState,
        imageErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnftData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setnftUpload(file);
      seterrorMessage((prevState) => ({
        ...prevState,
        imageErr: null,
      }));
    }
  };
  const handleActiveImage = (event) => {
    const file = event.target.files[0];
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterrorMessage((prevState) => ({
        ...prevState,
        activeimageErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnftData((prevState) => ({
        ...prevState,
        activeimage: file,
      }));
      setNFTActiveImage(file);
      seterrorMessage((prevState) => ({
        ...prevState,
        activeimageErr: null,
      }));
    }
  };
  const handleExpireImage = (event) => {
    const file = event.target.files[0];
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterrorMessage((prevState) => ({
        ...prevState,
        expireimageErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnftData((prevState) => ({
        ...prevState,
        expireimage: file,
      }));
      setNFTExpireImage(file);
      seterrorMessage((prevState) => ({
        ...prevState,
        expireimageErr: null,
      }));
    }
  };
  const handleUsedImage = (event) => {
    const file = event.target.files[0];
    if (!file.type.match(/^image\/(png|jpeg)$/)) {
      seterrorMessage((prevState) => ({
        ...prevState,
        usedimageErr: "Please upload a PNG or JPG image",
      }));

      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnftData((prevState) => ({
        ...prevState,
        usedimage: file,
      }));
      setNFTUsedImage(file);
      seterrorMessage((prevState) => ({
        ...prevState,
        usedimageErr: null,
      }));
    }
  };
  const handleexpirydate = (event) => {
    const date = event.target.value;

    if (date == "" || date == null || date == undefined) {
      seterrorMessage((prevState) => ({
        ...prevState,
        expiryErr: "Please Enter Expiry Date",
      }));
    } else {
      setnftData((prevState) => ({
        ...prevState,
        expirydate: date,
      }));
      seterrorMessage((prevState) => ({
        ...prevState,
        expiryErr: null,
      }));
    }
  };

  const validatePrice = (price) => {
    if (price <= 0) {
      return "Price should be greater than zero.";
    }
    if (price.toString().split(".")[1]?.length > 5) {
      return "Price can have a maximum of 5 decimal places.";
    }
    return null;
  };

  function handlePriceBlur(event) {
    const price = event.target.value;
    const errorMessage = validatePrice(price);
    seterrorMessage((prevState) => ({
      ...prevState,
      priceErr: errorMessage,
    }));
  }
  const handleDescriptionChange = (event) => {
    const description = event.target.value.trimStart();

    // validate description
    if (description.length < 10) {
      seterrorMessage((prevState) => ({
        ...prevState,
        descriptionErr: "Description must be at least 10 characters long",
      }));
    } else {
      seterrorMessage((prevState) => ({
        ...prevState,
        descriptionErr: null,
      }));
    }
    setnftData((prevState) => ({
      ...prevState,
      description: description,
    }));
  };

  const handleClose = () => setShow(false);

  const removeProperty = (index) => {
    if (addPropertiesList.length == 0) return;
    else {
      let filteredList = [...addPropertiesList.filter((item, i) => i != index)];
      setAddPropertiesList(filteredList);
    }
  };

  const characterCahngeHandler = (e, index) => {
    const itemToChange = addPropertiesList.find((item, i) => index === i);
    const ind = addPropertiesList.indexOf(itemToChange);
    addPropertiesList[ind].name = e.target.value;
    const data = [...addPropertiesList];
    setAddPropertiesList(data);
  };
  const rarityCahngeHandler = (e, index) => {
    const itemToChange = addPropertiesList.find((item, i) => index === i);
    const ind = addPropertiesList.indexOf(itemToChange);
    addPropertiesList[ind].rarity = e.target.value;
    const data = [...addPropertiesList];
    setAddPropertiesList(data);
  };

  const maleCahngeHandler = (e, index) => {
    const itemToChange = addPropertiesList.find((item, i) => index === i);
    const ind = addPropertiesList.indexOf(itemToChange);
    addPropertiesList[ind].name = e.target.value;
    const data = [...addPropertiesList];

    setAddPropertiesList(data);
  };

  const TypeChangeHandler = (e, index) => {
    const itemToChange = addPropertiesList.find((item, i) => index === i);
    const ind = addPropertiesList.indexOf(itemToChange);
    addPropertiesList[ind].type = e.target.value;

    const data = [...addPropertiesList];

    setAddPropertiesList(data);
  };
  const addMoreProperty = () => {
    setAddPropertiesList((prev) => {
      return [...prev, { type: "", name: "", raity: "", type: "Currency" }];
    });
  };
  const savePropertiesList = () => {
    const filter = addPropertiesList?.filter((item, index) => {
      return item?.type && item?.rarity;
    });

    setAddPropertiesList([...filter]);
    setShow(false);
    setFinalCreatedProperties([...filter]);
  };
  const toggleOnPropertiesModal = () => {
    setShow(true);
  };

  const handlenftSubmit = (e) => {
    e.preventDefault();
    const collection = id
      ? nftData?.collection
      : e.target.elements?.collection?.value;

    let temp = 0;

    if (
      !nftData?.image &&
      (nftType == NFT_TYPE.MarketPlace || nftType == NFT_TYPE.Promotion)
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        imageErr: "Image Required",
      }));
      temp++;
      toast.warn("Image Required");
      return;
    }

    if (
      (nftType == NFT_TYPE.Product ||
        nftType == NFT_TYPE.MarketPlace ||
        nftType == NFT_TYPE.Promotion) &&
      !nftData?.NFTProductListid
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        productErr: "Product is Required",
      }));
      toast.warn("Product is  Required");
      temp++;
      return;
    }
  

    if (
      !NFTActiveImage &&
      !id &&
      (nftType == NFT_TYPE.Tier || nftType == NFT_TYPE.Product)
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        activeimageErr: "Active Image Required",
      }));
      temp++;
      toast.warn("Active Image  is  Required");
      return;
    }

    if (
      !nftData?.expireimage &&
      !id &&
      (nftType == NFT_TYPE.Tier || nftType == NFT_TYPE.Product)
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        expireimageErr: "Expire Image Required",
      }));
      toast.warn("Expire Image Required");
      temp++;
      return;
    }
    if (
      !nftData?.usedimage &&
      !id &&
      (nftType == NFT_TYPE.Tier || nftType == NFT_TYPE.Product)
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        usedimageErr: "Used Image Required",
      }));
      toast.warn("Used Image Required");

      temp++;
      return;
    }

    if (
      (nftData?.expirydate == "" ||
        nftData?.expirydate == null ||
        nftData?.expirydate == undefined) &&
      (nftType != NFT_TYPE.MarketPlace || nftType != NFT_TYPE.Promotion)
    ) {
      seterrorMessage((prevState) => ({
        ...prevState,
        expiryErr: "Expiry Date Required",
      }));
      toast.warn("Expiry Image Required");

      temp++;
    }
    if (nftData?.nftcopies < 1) {
      seterrorMessage((prevState) => ({
        ...prevState,
        nftcopiesErr: "Enter Valid Copies",
      }));
      temp++;
    }
    if (!nftData?.calimCopy) {
      seterrorMessage((prevState) => ({
        ...prevState,
        calimCopyErr: "Enter Valid value",
      }));
      temp++;
    }

    // if (nftData?.nftcopies <= minCopies && nftType != NFT_TYPE.MarketPlace) {

    //   seterrorMessage((prevState) => ({
    //     ...prevState,
    //     nftcopiesErr: "Enter a valid number",
    //   }));
    //   temp++;
    // }
    let isNftcopiesValid =
      nftData?.nftcopies >= minCopies ||
      nftType === NFT_TYPE.MarketPlace ||
      nftType === NFT_TYPE.Promotion;
    if (!isNftcopiesValid) {
      seterrorMessage((prevState) => ({
        ...prevState,
        nftcopiesErr: "Enter a valid number",
      }));
      temp++;
      return;
    }
    

    if (!nftData?.nftName) {
      seterrorMessage((prevState) => ({
        ...prevState,
        nameErr: "NFT Name Required",
      }));
      temp++;
    }
    if (!nftData?.description) {
      seterrorMessage((prevState) => ({
        ...prevState,
        descriptionErr: "Description Required",
      }));
      temp++;
    }

    if (
      // errorMessage.priceErr ||
      // errorMessage.imageErr ||
      errorMessage.descriptionErr
    ) {
      temp++;
    }
    if (nftType == NFT_TYPE.Tier || nftType == NFT_TYPE.Product) {
      // if (!nftData?.royalty) {
      //   seterrorMessage((prevState) => ({
      //     ...prevState,
      //     royaltyErr: "Royalty Required",
      //   }));
      //   temp++;
      // }
    }

    if (!location.state) {
      if (!collection) {
        seterrorMessage((prevState) => ({
          ...prevState,
          collectionErr: "Collection Required",
        }));
        temp++;
      }
    }


    const baseFormData = new FormData();
    // Add all the common fields
    baseFormData.append("nftName", nftData?.nftName);
    baseFormData.append("description", nftData?.description);
    if (nftType == NFT_TYPE.MarketPlace || nftType == NFT_TYPE.Promotion) {
      baseFormData.append("Image", nftData?.image);
    }
    baseFormData.append(
      "collectionId",
      location.state?.collectionID ? location.state.collectionID : collection
    );
    baseFormData.append("DistributionType", `${nftType}`);
    baseFormData.append("TierType", tireType ?? 0);
    
    if (nftType != NFT_TYPE.MarketPlace || nftType != NFT_TYPE.Promotion) {
      baseFormData.append("Royalty", nftData?.royalty ?? "0");
      baseFormData.append("ActiveImage", nftData?.activeimage);
      baseFormData.append("ExpiredImage", nftData?.expireimage);
      baseFormData.append("UsedImage", nftData?.usedimage);
    }
    
    baseFormData.append("DiscountCode", nftData?.discountCode);
    baseFormData.append("ExpiryDate", nftData?.expirydate);
    baseFormData.append("GTIN", nftData?.HTNnumber);
    baseFormData.append("ProductId", nftData?.NFTProductListid);
    baseFormData.append("ProductName", nftData?.NFTProductName);
    baseFormData.append("CalimCopy", nftData?.calimCopy || 0);
    if ((nftType == NFT_TYPE.MarketPlace || nftType == NFT_TYPE.Promotion)) {
      baseFormData.append("ProductId", nftData?.NFTProductListid);
      baseFormData.append("ProductName", nftData?.NFTProductName);
      baseFormData.append("price", nftData?.price ?? '0');
    }    

    if ((nftType == NFT_TYPE.Product)) {
   
      baseFormData.append("price", nftData?.price ?? '0');
    }        if (!SelectedConmpany) {
      baseFormData.append("CompanyId", nftData?.companyId);
    }
    
    baseFormData.append("NftProperties", JSON.stringify(finalCreatedProperties));

    setloader(true);

    if (id) {
      // Handle update logic
      baseFormData.append("NftId", id);
      const locationId = stores[0]?.value;
      baseFormData.append("LocationId", locationId);
      baseFormData.append("NftCopy", nftData?.nftcopies);
      
      axios.put(API_URL + `api/Nft/EditNft`, baseFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        setloader(false);
        toast.success("NFT updated Successfully");
        navigate(`/CollectionDetail/${id}`);
            })
      .catch((err) => {
        setloader(false);
      });
    } else {
      // Handle creation logic for multiple stores
      const createPromises = stores.map(store => {
        // Create a new FormData for each store to avoid mutation
        const storeFormData = new FormData();
        
        // Append all base fields
        for (let pair of baseFormData.entries()) {
          storeFormData.append(pair[0], pair[1]);
        }
        
        // Add store-specific data
        storeFormData.append("LocationId", store.value);
        storeFormData.append("NftCopy", nftData?.storeCopies?.[store.value] || 0);

        // Make API call for this store


        return axios.post(API_URL + `api/Nft/AddNft`, storeFormData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        });
      });

      Promise.all(createPromises)
        .then(responses => {
          setloader(false);
          const successfulCreations = responses.filter(res => res.data?.data?.nftId);
          
          if (successfulCreations.length === stores.length) {
            toast.success(`NFTs Created Successfully for all stores`);
            navigate(`/Collection/${location.state.collectionID}`);
            setnftData({
              nftName: "",
              image: "",
              activeimage: "",
              expireimage: "",
              usedimage: "",
              expirydate: "",
              price: "",
              nftcopies: "",
              calimCopy: "",
              locationid: "",
              discountCode: "",
              description: "",
              royalty: 0,
              collection: "",
              productList: "",
              NFTProductListid: null,
              HTNnumber: null
            });
            setStores([]);
          } else {
            toast.warning(`Some NFTs failed to create. Please check and try again.`);
          }
        })
        .catch(error => {
          setloader(false);
          toast.error(`Failed to create NFTs. Please try again.`);
        });
    }
  };

  const HandleChangeNFTType = (e) => {
    const type = e?.target?.value;

    setnftType(type);
  };
  const HandleChangeNFTProductType = async (e) => {
    if (e) {
      // First update nftData with the new product info
      setnftData({
        ...nftData,
        NFTProductName: e?.name,
        NFTProductListid: e?.id,
        HTNnumber: e?.sku,
        locationid: e?.locationid,
        companyId: e.companyid,
      });

      // Then fetch the quantity for this product
      await fetchProductQuantity();

      seterrorMessage((prevState) => ({
        ...prevState,
        productErr: "",
      }));
    } else {
      seterrorMessage((prevState) => ({
        ...prevState,
        productErr: "",
      }));
      setnftData({
        ...nftData,
        NFTProductListid: null,
        HTNnumber: "",
      });
      setQuantity(0);
    }
  };

  const HandleChangeTireType = (e) => {
    const type = e?.target?.value;

    settireType(type);
  };

  useEffect(() => {
    const selectedTireType = tireTypeMappings[tireType];
    const selectedTire = tiresList.find(
      (tire) => tire.tireType === selectedTireType
    );
    const minCopies = selectedTire ? selectedTire.tireCount : 0;

    setminCopies(minCopies);
  }, [tireType]);

  const [stores, setStores] = useState([]);
  const GetAllLocations = () => {
    axios
      .get(`${Node_BE_URL}/api/admin/getAllLocations`)
      .then((data) => {
        setAllLocations(data.data);
        const formattedOptions = data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(formattedOptions);
      })
      .catch((err) => {
        console.error("Error fetching locations:", err);
      });
  };

  useEffect(() => {
    GetAllLocations();
  }, []);

  const handleSelectChange = (selectedOptions) => {
    if (nftType == NFT_TYPE.Tier) {
    }
    setStores(selectedOptions || []);
  };
  useEffect(() => {
    setStores([]);
  }, [nftType]);

  // const options = allLocation?.map((item) => ({
  //   value: item.id,
  //   label: item.name,
  // }));
  const handleSelectChangeproduct = (selectedOptions) => {
    setStores(selectedOptions || []);
  };

  const fetchProductQuantity = async () => {
    if (!nftData?.NFTProductListid || !nftData?.locationid) {
      return; // Avoid API call if data is missing
    }

    try {
      const payload = {
        id: nftData.NFTProductListid,
        locationId: nftData.locationid,
      };
      const response = await axios.post(
        `${Node_BE_URL}/api/getProductsquantity`,
        payload
      );
      setQuantity(response.data.data.quantity);
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchProductQuantity();
  }, [nftData]); // ✅ Added `nftData` as a dependency

  return (
    <>
      <Sidebar />

      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/NFTCollectionManagement">
                  NFT Collection Management
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  {id ? "Update" : "Add New "} NFT
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>

            <div className="full-div">
              <h1>{id ? "Update" : "Add New "} NFT</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form
                onSubmit={(e) => handlenftSubmit(e)}
                style={{ maxWidth: "900px" }}
              >
                {(nftType == NFT_TYPE.MarketPlace ||
                  nftType == NFT_TYPE.Promotion) && (
                  <Form.Group
                    className="mb-3 file-uploader-pnl"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Upload Photo (Only png and jpeg are supported.)
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      className="reg-btn big w-100 br-rad"
                      type="file"
                      accept=".jpg,.png"
                      onChange={handleImageUpload}
                    />
                    {errorMessage.imageErr && (
                      <p className="error-msg">{errorMessage.imageErr}</p>
                    )}
                    {(nftUpload || nftData?.image) && (
                      <>
                        {nftUpload ? (
                          <div>
                            <img
                              className="image-container"
                              style={{
                                height: "200px",
                                width: "200px",
                                objectFit: "contain",
                              }}
                              src={URL.createObjectURL(nftUpload)}
                              alt="logofig"
                            />
                          </div>
                        ) : (
                          <img
                            className="img-container"
                            src={`${API_URL}${nftData?.image}`}
                            alt="logofig"
                          />
                        )}
                      </>
                    )}
                  </Form.Group>
                )}

                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        required={true}
                        value={nftData?.nftName}
                        onChange={(e) => {
                          setnftData((prevState) => ({
                            ...prevState,
                            nftName: e.target.value.trimStart(),
                          }));
                          if (e.target.value.length > 4) {
                            seterrorMessage((prevState) => ({
                              ...prevState,
                              nameErr: "",
                            }));
                          }
                        }}
                        name="nftName"
                        type="text"
                        placeholder="Item Name"
                      />
                      {errorMessage.nameErr && (
                        <p className="error-msg">{errorMessage.nameErr}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        required={true}
                        onChange={handleDescriptionChange}
                        value={nftData?.description}
                        name="description"
                        type="text"
                        placeholder="e.g.This is very limited item"
                      />
                      {errorMessage.descriptionErr && (
                        <p className="error-msg">
                          {errorMessage.descriptionErr}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  {(nftType == NFT_TYPE.MarketPlace ||
                    nftType == NFT_TYPE.Promotion) && (
                    <Col md="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setnftData((prevState) => ({
                              ...prevState,
                              price: e.target.value,
                            }));
                            seterrorMessage((prevState) => ({
                              ...prevState,
                              priceErr: "",
                            }));
                          }}
                          min="0"
                          required="true"
                          value={nftData?.price}
                          name="price"
                          type="number"
                          placeholder="Price"
                        />
                        {errorMessage.royaltyErr && (
                          <p className="error-msg">{errorMessage.royaltyErr}</p>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Form.Group className="mb-3" controlId="properties">
                  <Form.Label>
                    Properties
                    <div className="child2">
                      <i
                        onClick={toggleOnPropertiesModal}
                        className="fa fa-fw"
                        aria-hidden="true"
                        title="Properties"
                      >
                        
                      </i>
                    </div>
                  </Form.Label>
                </Form.Group>

                <Row>
                  {/* {finalCreatedProperties &&
                    finalCreatedPropertie\][p21`890-p[;'
                      ?((data, index) => {
                      return (
                        <Col
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          className={
                            "d-flex justify-content-center flex-column align-items-center mt-3 word-break-breakall"
                          }
                          key={index}
                        >
                          <div
                            className="w-100"
                            style={{
                              backgroundColor: "rgba(21, 178, 229, 0.06)",
                              borderRadius: 6,
                              border: "1px solid #AD79F7",
                              padding: "5px 5px",
                              textAlign: "center",
                              wordBreak: "break",
                              background: "#AD79F7",
                              color: "#fff",
                              marginBottom: "10px",
                            }}
                          >
                            <p>{data?.name}</p>
                            <h4>
                              <strong>{data?.type} </strong>
                            </h4>
                            <p>{"$ " + data?.rarity}</p>
                          </div>
                        </Col>
                      );
                    })} */}
                  {finalCreatedProperties &&
                    finalCreatedProperties.length > 0 &&
                    finalCreatedProperties.map((item, index) => {
                      return (
                        <Col
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          className={
                            "d-flex justify-content-center flex-column align-items-center mt-3 word-break-breakall"
                          }
                        >
                          {
                            <div className="w-100 PropertiesDetail">
                              <p>{item.type}</p>
                              <h4>
                                <strong>{item.name} </strong>
                              </h4>
                              <p>
                                {item.type !== "Currency" ? (
                                  <img
                                    height={20}
                                    width={20}
                                    className="Min-Prize"
                                    src={Logo}
                                    alt=""
                                  />
                                ) : (
                                  "$"
                                )}

                                {item.rarity}
                              </p>
                            </div>
                          }
                        </Col>
                      );
                    })}
                </Row>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Select NFT Type Category</Form.Label>
                  <Form.Select
                    required
                    // value={id && selectedCat}
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => HandleChangeNFTType(e)}
                    disabled={id && true}
                    value={id && nftType}
                    Ftitl
                  >
                    <option value="" disabled selected>
                      Choose Option
                    </option>

                    {distributionType?.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {/* <Col md="6"> */}
                {(nftType == NFT_TYPE.MarketPlace ||
                  nftType == NFT_TYPE.Promotion ||
                  nftType == NFT_TYPE.Tier) && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Store Name</Form.Label>

                      <ReactMultiSelectCheckboxes
                        options={options}
                        value={stores}
                        onChange={handleSelectChange}
                        labelledBy="Select Stores"
                        className="multi-select"
                        styles={customStyles}
                        placeholder="Stores selected"
                      />
                    </Form.Group>
                    {!colID && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Preferred Province</Form.Label>
                        <Form.Select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => {
                            seterrorMessage((prevState) => ({
                              ...prevState,
                              store: "",
                            }));
                            setSelectedConmpany(
                              e.target.value === "" ? null : e.target.value
                            );
                          }}
                        >
                          <option value={""}> Select Company </option>
                          {CompaniesLocation?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}-{item.id}
                            </option>
                          ))}
                        </Form.Select>
                        {errorMessage.store && (
                          <p className="error-msg">{errorMessage.store}</p>
                        )}
                      </Form.Group>
                    )}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Product</Form.Label>
                      <Button
                        className="reg-btn mb-3 big block"
                        onClick={() => setProdcutListModal(true)}
                      >
                        Select Product
                      </Button>
                      {errorMessage.productErr && (
                        <p className="error-msg">{errorMessage.productErr}</p>
                      )}

                      <Row>
                        <Col md="6">
                          <Form.Group className="mb-3">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              disabled
                              value={nftData?.NFTProductName}
                            />
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>GTIN Number</Form.Label>
                            <Form.Control disabled value={nftData?.HTNnumber} />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control
                              required
                              type="date"
                              id="meeting-time"
                              name="meeting-time"
                              value={nftData?.expirydate}
                              min={today}
                              onChange={(event) => handleexpirydate(event)}
                            />
                            {errorMessage.expiryErr && (
                              <p className="error-msg">
                                {errorMessage.expiryErr}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    {nftType != NFT_TYPE.Tier && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>NFT Copies per Store</Form.Label>
                      {stores.map((store, index) => (
                        <div
                          key={index}
                          className="store-copies-container mb-2"
                        >
                          <Form.Label>{store.label}</Form.Label>
                          <Form.Control
                            required
                            value={nftData?.storeCopies?.[store.value] ?? quantity ?? ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Check if value is a number
                              if (/^\d*$/.test(value)) {
                                setnftData((prevState) => ({
                                  ...prevState,
                                  storeCopies: {
                                    ...(prevState.storeCopies || {}),
                                    [store.value]: value,
                                  },
                                  // Set nftcopies to the current store's value instead of the sum
                                  nftcopies: value,
                                }));

                                // Reset error message if needed
                                if (value > minCopies) {
                                  seterrorMessage((prevState) => ({
                                    ...prevState,
                                    nftcopiesErr: "",
                                  }));
                                }
                              }
                            }}
                            name={`nftcopies-${store.value}`}
                            placeholder={`Enter copies for ${store.label}`}
                            type="text"
                          />
                        </div>
                      ))}
                      {errorMessage.nftcopiesErr && (
                        <p className="error-msg">
                          {errorMessage.nftcopiesErr}
                        </p>
                      )}
                    </Form.Group>
                    )}
                  </>
                )}
                {nftType == NFT_TYPE.Product && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Store Name</Form.Label>

                      <ReactMultiSelectCheckboxes
                        options={options}
                        value={stores}
                        onChange={handleSelectChangeproduct}
                        labelledBy="Select Stores"
                        className="multi-select"
                        styles={customStyles}
                        placeholder="Stores selected"
                      />
                    </Form.Group>
                    {!colID && (
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Preferred Province</Form.Label>
                        <Form.Select
                          className="form-control"
                          aria-label="Default select example"
                          onChange={(e) => {
                            seterrorMessage((prevState) => ({
                              ...prevState,
                              store: "",
                            }));
                            setSelectedConmpany(
                              e.target.value === "" ? null : e.target.value
                            );
                          }}
                        >
                          <option value={""}> Select Company </option>
                          {CompaniesLocation?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}-{item.id}
                            </option>
                          ))}
                        </Form.Select>
                        {errorMessage.store && (
                          <p className="error-msg">{errorMessage.store}</p>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Product</Form.Label>
                      <Button
                        className="reg-btn mb-3 big block"
                        onClick={() => setProdcutListModal(true)}
                      >
                        Select Product
                      </Button>
                      {errorMessage.productErr && (
                        <p className="error-msg">{errorMessage.productErr}</p>
                      )}

                      <Row>
                        <Col md="6">
                          <Form.Group className="mb-3">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              disabled
                              value={nftData?.NFTProductName}
                            />
                          </Form.Group>
                        </Col>

                        <Col md="6">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>GTIN Number</Form.Label>
                            <Form.Control disabled value={nftData?.HTNnumber} />
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* {true&&<DropdownWithSearch  data={NFTpropertyList} id={nftData?.productList} onChange={HandleChangeNFTProductType} />} */}

                      {/* <Form.Select
                        required
                        // defaultValue={'default'}
                        className="form-control"
                        aria-label="Default select example"
                        onChange={(e) => HandleChangeNFTProductType(e)}
                      >
                        <option value="" disabled>
                          Choose Option
                        </option>
                        {NFTpropertyList?.map((option) => (
                          <option key={option?.id} value={JSON.stringify(option)} selected={nftData?.productList === option?.id}>
                            {option?.productName}
                          </option>
                        ))}
                      </Form.Select>

 */}
                    </Form.Group>

                    {/* <div className="mb-3">
                      <label>Product list</label>
                      <SelectSearch
                        style={{ backgroundColor  : 'white' }}
                        options={NFTpropertyList.map((option) => ({
                          value: JSON.stringify(option),
                          name: option.productName,
                          gtin: option.gtin
                        }))}
                        container={}
                        search
                        placeholder="Choose Option"
                        onChange={(e) => HandleChangeNFTProductType(e)}
                      />
                    </div> */}

                    {/* {nftData?.NFTProductListid && */}

                    {/* } */}

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>NFT Copies</Form.Label>
                      <Form.Control
                        required
                        value={nftData?.nftcopies}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Check if the value contains only numbers
                          if (/^\d*$/.test(value)) {
                            setnftData((prevState) => ({
                              ...prevState,
                              nftcopies: value,
                            }));
                          }
                          if (e.target.value > minCopies) {
                            seterrorMessage((prevState) => ({
                              ...prevState,
                              nftcopiesErr: "",
                            }));
                          }
                        }}
                        name="nftcopies"
                        type="text"
                        placeholder="Enter NFT Copies"
                      />
                      {errorMessage.nftcopiesErr && (
                        <p className="error-msg">{errorMessage.nftcopiesErr}</p>
                      )}
                    </Form.Group>
                  </>
                )}
                <>
                  {nftType == NFT_TYPE.Promotion && (
                    <Form.Group
                      className="mb-3"
                      controlId="formAdditionalAmount"
                    >
                      <Form.Label>Maximum copies claimed by 1 user</Form.Label>
                      <Form.Control
                        required
                        value={nftData?.calimCopy}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow empty string and check if the value contains only numbers
                          if (value === "" || /^\d*$/.test(value)) {
                            if (
                              value === "" ||
                              parseInt(value) <= parseInt(nftData.nftcopies)
                            ) {
                              setnftData((prevState) => ({
                                ...prevState,
                                calimCopy: value,
                              }));
                              seterrorMessage((prevState) => ({
                                ...prevState,
                                calimCopyErr: "",
                              }));
                            } else {
                              // Show an error if value exceeds nftcopies
                              seterrorMessage((prevState) => ({
                                ...prevState,
                                calimCopyErr:
                                  "Claim copy cannot exceed NFT Copies",
                              }));
                            }
                          }
                        }}
                        name="calimCopy"
                        type="text"
                        placeholder="Maximum copies claimed by 1 user"
                      />
                      {errorMessage.calimCopyErr && (
                        <p className="error-msg">{errorMessage.calimCopyErr}</p>
                      )}
                    </Form.Group>
                  )}
                </>
                {/* </Col> */}

                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Store Name</Form.Label>
                        <Form.Select
                    required
                    // value={id && selectedCat}
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) =>       setnftData((prevState) => ({
                      ...prevState,
                      locationId: e.target.value
                    })) }
                    disabled={id && true}
                    value={id && nftType} Ftitl
                  >
                    <option value="" disabled selected>
                      Choose Option
                    </option>


                    {allLocation?.map((option) => (
                      <option key={option?.id} value={option?.id}>
                        {option?.name}
                      </option>
                    ))}
                  </Form.Select>
                      </Form.Group> */}

                {nftType == NFT_TYPE.Tier && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Tier Type</Form.Label>
                      <Form.Select
                        required
                        disabled={id && true}
                        value={id && Data.tierType}
                        className="form-control"
                        aria-label="Default select example"
                        onChange={(e) => HandleChangeTireType(e)}
                        // value={selectedCat}
                      >
                        <option value="" disabled selected>
                          Choose Option
                        </option>

                        {id ? (
                          <option>{Data.tierType}</option>
                        ) : (
                          tireNFTType?.map((option) => (
                            <option key={option?.value} value={option?.value}>
                              {option?.name}
                            </option>
                          ))
                        )}
                      </Form.Select>
                      {/* {errorMessage.priceErr && (
                        <p className="error-msg">{errorMessage.priceErr}</p>
                      )} */}
                    </Form.Group>
                  </>
                )}
                {nftType == NFT_TYPE.Tier && (
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Minimum Copies</Form.Label>
                        <Form.Control
                          disabled
                          required
                          value={minCopies}
                          // onChange={(e) => {

                          //     setnftData((prevState) => ({
                          //       ...prevState,
                          //       nftcopies: e.target.value,
                          //     }));

                          // }}
                          name="nftName"
                          type="number"
                          // placeholder="Item Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Maximum Copies</Form.Label>
                        <Form.Control
                          required
                          value={nftData?.nftcopies}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value contains only numbers
                            if (/^\d*$/.test(value)) {
                              setnftData((prevState) => ({
                                ...prevState,
                                nftcopies: e.target.value,
                              }));
                            }
                          }}
                          name="nftName"
                          type="text"
                          placeholder="Maximum Copies"
                        />
                        {minCopies > nftData?.nftcopies && (
                          <p className="error-msg">
                            {errorMessage.nftcopiesErr}
                          </p>
                        )}
                        {/* {nftData?.nftcopies <= minCopies && <p className="error-msg">Max copies can't be less than  min. number of copies</p>} */}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {(nftType == NFT_TYPE.Product || nftType == NFT_TYPE.Tier) && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Discount Code</Form.Label>
                    <Form.Control
                      required
                      value={nftData?.discountCode}
                      onChange={(e) => {
                        setnftData((prevState) => ({
                          ...prevState,
                          discountCode: e.target.value,
                        }));
                      }}
                      // onBlur={handlePriceBlur}
                      name="price"
                      type="text"
                      placeholder="Enter Discount Code"
                    />
                    {errorMessage.discountCode && (
                      <p className="error-msg">
                        {errorMessage.discountCodeErr}
                      </p>
                    )}
                  </Form.Group>
                )}
                {nftType == NFT_TYPE.Promotion && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Discount Code (optional)</Form.Label>
                    <Form.Control
                      value={nftData?.discountCode}
                      onChange={(e) => {
                        setnftData((prevState) => ({
                          ...prevState,
                          discountCode: e.target.value,
                        }));
                      }}
                      // onBlur={handlePriceBlur}
                      name="price"
                      type="text"
                      placeholder="Enter Discount Code"
                    />
                    {errorMessage.discountCode && (
                      <p className="error-msg">
                        {errorMessage.discountCodeErr}
                      </p>
                    )}
                  </Form.Group>
                )}

                {(nftType == NFT_TYPE.Product || nftType == NFT_TYPE.Tier) && (
                  <>
                    <Form.Group
                      className="mb-3 file-uploader-pnl"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Upload NFT Active Photo</Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        className="reg-btn big w-100 br-rad"
                        type="file"
                        accept=".jpg,.png"
                        onChange={handleActiveImage}
                      />
                      {errorMessage.activeimageErr && (
                        <p className="error-msg">
                          {errorMessage.activeimageErr}
                        </p>
                      )}

                      {(NFTActiveImage || Data.activeImage) && (
                        <>
                          {NFTActiveImage ? (
                            <div style={{ height: "200px", width: "200px" }}>
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                src={URL.createObjectURL(NFTActiveImage)}
                                alt="logofig"
                              />
                            </div>
                          ) : (
                            <img
                              style={{ height: "200px", width: "200px" }}
                              src={`${API_URL}${Data.activeImage}`}
                              alt="logofig"
                            />
                          )}
                        </>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3 file-uploader-pnl"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Upload NFT Redeemed- Photo</Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        className="reg-btn big w-100 br-rad"
                        type="file"
                        accept=".jpg,.png"
                        onChange={handleUsedImage}
                      />
                      {errorMessage.usedimageErr && (
                        <p className="error-msg">{errorMessage.usedimageErr}</p>
                      )}
                      {(NFTUsedImage || Data.usedImage) && (
                        <>
                          {NFTUsedImage ? (
                            <div style={{ height: "200px", width: "200px" }}>
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                src={URL.createObjectURL(NFTUsedImage)}
                                alt="logofig"
                              />
                            </div>
                          ) : (
                            <img
                              style={{ height: "200px", width: "200px" }}
                              src={`${API_URL}${Data.usedImage}`}
                              alt="logofig"
                            />
                          )}
                        </>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 file-uploader-pnl"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Upload NFT Expire Photo</Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        className="reg-btn big w-100 br-rad"
                        type="file"
                        accept=".jpg,.png"
                        onChange={handleExpireImage}
                      />
                      {errorMessage.activeimageErr && (
                        <p className="error-msg">
                          {errorMessage.expireimageErr}
                        </p>
                      )}
                      {(NFTExpireImage || Data.expiredImage) && (
                        <>
                          {NFTExpireImage ? (
                            <div style={{ height: "200px", width: "200px" }}>
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                src={URL.createObjectURL(NFTExpireImage)}
                                alt="logofig"
                              />
                            </div>
                          ) : (
                            <img
                              style={{ height: "200px", width: "200px" }}
                              src={`${API_URL}${Data.expiredImage}`}
                              alt="logofig"
                            />
                          )}
                        </>
                      )}
                    </Form.Group>
                  </>
                )}

                {nftType == NFT_TYPE.Product && (
                  <>
                    <Form.Group
                      className="mb-3 file-uploader-pnl"
                      controlId="formBasicEmail"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="date">Expiry Date:</label>
                        <input
                          type="date"
                          id="date"
                          required="true"
                          name="date"
                          value={nftData?.expirydate}
                          min={today}
                          onChange={(event) => handleexpirydate(event)}
                        />
                      </div>
                    </Form.Group>
                    {errorMessage.expiryErr && (
                      <p className="error-msg">{errorMessage.expiryErr}</p>
                    )}
                  </>
                )}

                {/* {!location.state && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Choose Collection</Form.Label>
                    <Form.Select
                      value={nftData?.collection}
                      onChange={(e) => {
                        seterrorMessage((prevState) => ({
                          ...prevState,
                          collectionErr: "",
                        }));
                        setnftData((prevState) => ({
                          ...prevState,
                          collection: e.target.value,
                        }));
                      }}
                      name="collection"
                      className="form-control"
                      aria-label="Default select example"
                    >
                      {!id && (
                        <option value="" disabled selected>
                          Select an option
                        </option>
                      )}

                      {collectoins.map((collectionData) => (
                        <option
                          value={collectionData.id}
                          key={collectionData.id}
                        >
                          {collectionData.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errorMessage.collectionErr && (
                      <p className="error-msg">{errorMessage.collectionErr}</p>
                    )}
                  </Form.Group>
                )} */}

                {/* Properties modal */}

                <Modal
                  show={show}
                  onHide={handleClose}
                  animation={true}
                  centered
                >
                  <Modal.Header className="modal-header-color">
                    <Modal.Title className="" style={{ color: "#AD79F7" }}>
                      Add Properties
                    </Modal.Title>
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        marginTop: "8px",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <CrossIcon />
                    </div>

                    {/* <div
                                            aria-label="Hide"
                                            onClick={handleClose}
                                            className="btn-close"
                                        >  */}
                    {/* <CrossIcon /> */}

                    {/* </div> */}
                  </Modal.Header>
                  <Modal.Body className="modal-body-color">
                    <p>
                      Properties show up underneath your item, are clickable,
                      and can be filtered in your collection's sidebar.
                    </p>
                    <Row style={{ paddingBottom: "5px" }}>
                      <Col xs={1}></Col>
                      <Col xs={4}>
                        <span
                          className=""
                          style={{ fontWeight: "bold", color: "#AD79F7" }}
                        >
                          Title
                        </span>
                      </Col>
                      <Col xs={3}>
                        <span
                          className=""
                          style={{ fontWeight: "bold", color: "#AD79F7" }}
                        >
                          Value
                        </span>
                      </Col>
                      <Col xs={3}>
                        <span
                          className=""
                          style={{ fontWeight: "bold", color: "#AD79F7" }}
                        >
                          Type
                        </span>
                      </Col>
                      {/* <Col xs={3}>
                        <span
                          className=""
                          style={{ fontWeight: "bold", color: "#AD79F7" }}
                        >
                          Rarity
                        </span>
                      </Col> */}
                    </Row>
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {addPropertiesList?.map((item, index) => {
                        return (
                          <div
                            style={{
                              border: "1px solid #c7a7a7b9",
                              borderRadius: "4px",
                              // marginTop: "10px",
                            }}
                            key={index}
                          >
                            <Row style={{ height: "40px" }}>
                              <Col xs={1}>
                                <div
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                    marginTop: "8px",
                                  }}
                                  onClick={() => {
                                    removeProperty(index);
                                  }}
                                >
                                  <CrossIcon />
                                </div>
                              </Col>
                              <Col xs={4}>
                                <input
                                  placeholder="Discout"
                                  maxLength={15}
                                  onChange={(e) => {
                                    const regex = /^(?![\s-])[\w\s-]*$/;
                                    if (regex.test(e.target.value)) {
                                      maleCahngeHandler(e, index);
                                    }
                                  }}
                                  className="form-control black"
                                  value={item.name}
                                  type="text"
                                  style={{
                                    borderRight: "1px solid #c7a7a7b9",
                                    borderLeft: "1px solid #c7a7a7b9",
                                    height: 40,
                                  }}
                                />
                              </Col>

                              <Col xs={3}>
                                <input
                                  style={{
                                    borderRight: "1px solid #c7a7a7b9",
                                    borderLeft: "1px solid #c7a7a7b9",
                                    height: 40,
                                  }}
                                  placeholder="Price"
                                  type="text"
                                  required="true"
                                  maxLength={15}
                                  className="form-control black"
                                  value={item.rarity}
                                  onChange={(e) => {
                                    const regex = /^(?:[0-9]+\.?|\.)*$/;
                                    if (regex.test(e.target.value)) {
                                      rarityCahngeHandler(e, index);
                                    }
                                    // const regex = /^(?![\s-])[\w\s-]*$/;
                                  }}
                                />
                              </Col>
                              <Col xs={4}>
                                <select
                                  className="form-control black propertiesDropDown"
                                  onChange={(e) => {
                                    TypeChangeHandler(e, index);
                                  }}
                                >
                                  {Dropdown.map((dropItem, index) => {
                                    return (
                                      <>
                                        <option
                                          id={`DropItem#${index}`}
                                          selected={dropItem === item.type}
                                          value={dropItem}
                                        >
                                          {dropItem}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>

                    <button
                      onClick={addMoreProperty}
                      style={{
                        padding: "10px",
                        border: "2px solid #AD79F7",
                        color: "#AD79F7",
                        fontWeight: "bold",
                        background: "transparent",
                        borderRadius: "6px",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                    >
                      Add more
                    </button>
                  </Modal.Body>
                  <Modal.Footer className="modal-footer-color">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <button className="save-btn" onClick={savePropertiesList}>
                        Save
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>

                {/* product list modal */}

                <Modal
                  show={prodcutListModal}
                  onHide={() => setProdcutListModal(false)}
                  animation={true}
                  centered
                  style={{ minWidth: "100%" }}
                >
                  <Modal.Header className="modal-header-color">
                    <Modal.Title className="" style={{ color: "#AD79F7" }}>
                      Product list
                    </Modal.Title>
                    <div
                      className="cross-modal"
                      onClick={() => setProdcutListModal(false)}
                    >
                      <CrossIcon />
                    </div>

                    {/* <div
                                            aria-label="Hide"
                                            onClick={handleClose}
                                            className="btn-close"
                                        >  */}
                    {/* <CrossIcon /> */}

                    {/* </div> */}
                  </Modal.Header>
                  <Modal.Body className="modal-body-color">
                    <InfiniteScrollList
                      setloader={setloader}
                      onPress={HandleChangeNFTProductType}
                      count={productList?.count}
                      closeModal={() => setProdcutListModal(false)}
                    />
                  </Modal.Body>
                  {/* <Modal.Footer className="modal-footer-color">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <button
                        style={{
                          background: "#AD79F7",
                          color: "white",
                          border: "none",
                          padding: "10px 16px",
                          borderRadius: "8px",
                        }}
                        onClick={savePropertiesList}
                      >
                        Save
                      </button>
                    </div>
                  </Modal.Footer> */}
                </Modal>

                <ul className="half-list">
                  <li>
                    <Button
                      type="submit"
                      className="reg-btn w-100 big"
                      id="create-btn"
                    >
                      {id ? "Update" : "Create"} NFT
                    </Button>
                    {/* <Link to="/" className='reg-btn w-100 big'>Create NFT</Link> */}
                  </li>
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          id
                            ? `/Collection/${colID}`
                            : `/Collection/${location.state.collectionID}`
                        )
                      }
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Button>
                    {/* <Link to="/" className='reg-btn w-100 trans big'>Cancel</Link> */}
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default AddNewNFT;
