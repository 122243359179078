import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Table, Button } from "react-bootstrap";
import axios from "axios";
import CustomLoader from "../Components/CustomeLoader";
import { API_URL, GetAllLocations, handleSaveUrl } from "../constant";
// import EditIcon from "../SVG/EditIcon";
import { toast } from "react-toastify";
import EditIcon from "../assets/images/edit.png";

const UpdateStore = () => {
  const [locations, setLocations] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [editingId, setEditingId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const itemsPerPage = 10;
  const token = localStorage.getItem("Token");
  const inputRef = useRef(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editedName, setEditedName] = useState("");

  // Fetch all locations
  const fetchLocations = (page) => {
    GetAllLocations(
      API_URL,
      page,
      token,
      setLoader,
      setLocations,
      setTotalPages,
      itemsPerPage
    );
  };

  useEffect(() => {
    fetchLocations(currentPage);
  }, [currentPage]);


  // Handle Save
  const onSaveUrl = (locationId) => {
    setLoader(true);
    handleSaveUrl(
      API_URL,
      token,
      locationId,
      locations,
      setLocations,
      setEditingId
    );
    setLoader(false);
  };
  const handleEditClick = (location) => {
    setEditingRow(location.id);
    setEditedName(`${location.company} - ${location.locationName}`);
  };

  const handleSaveEdit = async (location) => {
    setLoader(true);
    try {
      const [company, locationName] = editedName.split(" - ");

      const response = await axios.put(
        `${API_URL}api/locations/update/${location.id}`,
        {
          company,
          locationName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Store name updated successfully!");
        setLocations(
          locations.map((loc) => {
            if (loc.id === location.id) {
              return { ...loc, company, locationName };
            }
            return loc;
          })
        );
        setEditingRow(null);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to update store name"
      );
      console.error("Error updating store name:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              <Breadcrumb>
                <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Update Store Names</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="table-container">
              <div className="table-container-inner">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Store Name</th>
                      <th>Company ID</th>
                      <th>Location ID</th>
                      <th>Province</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loader && locations.length > 0
                      ? locations?.map((location) => (
                          <tr key={location.id}>
                            <td>
                              {editingRow === location.id ? (
                                <div className="d-flex align-items-center">
                                  <input
                                    type="text"
                                    value={editedName}
                                    onChange={(e) =>
                                      setEditedName(e.target.value)
                                    }
                                    className="form-control me-2"
                                  />
                                  <Button
                                    variant="success"
                                    size="sm"
                                    onClick={() => handleSaveEdit(location)}
                                    className="me-2"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => setEditingRow(null)}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  {location.company} - {location.locationName}
                                  <img
                                    src={EditIcon}
                                    alt="EditIcon"
                                    className="Editicon"
                                    onClick={() => handleEditClick(location)}
                                  />
                                </>
                              )}
                            </td>
                            <td>{location.companyID || "N/A"}</td>
                            <td>{location.locationId || "N/A"}</td>
                            <td>{location.province || "N/A"}</td>
                          </tr>
                        ))
                      : !loader && (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateStore;
