import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./header";
import { Breadcrumb, Button, Form, Modal, Table, Tabs } from "react-bootstrap";
import CustomLoader from "./CustomeLoader";
import moment from "moment";
import {
  API_URL,
  CompanyLocation,
  GetAllLocations,
  Node_BE_URL,
  validateEmail,
} from "../constant";
import PaginationComponent from "../pages/PaginationComponent";
import { toast } from "react-toastify";
import { fetchLogs } from "../shared/api";
import coins from "../assets/images/1cmtokenlogo.png";
import CopyIcon from "../assets/images/icons/copy.png";
import { DateRange } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const UserPoints = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState('');
  const itemsPerPage = 20;
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const loadLogs = async (
    startDate = null,
    endDate = null,
    searchTerm = ""
  ) => {
    setLoading(true);
    try {
      const { logs, totalLogs, message } = await fetchLogs(
        Node_BE_URL,
        currentPage,
        itemsPerPage,
        startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        searchTerm
      );

      if (message === "No logs found") {
        toast.error("Invalid email");
      } else {
        setLogs(logs);
        setTotalItems(totalLogs);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid email");
      } else {
        console.error("Failed to load logs:", error);
        toast.error("An error occurred while loading logs.");
      }
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const selectedRange = dateRange[0];
    loadLogs(
      selectedRange.startDate,
      selectedRange.endDate,
      searchTerm
    );
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Make sure this updates the state
  };

  const handleCopyGluserId = (gluserId) => {
    if (gluserId) {
      navigator.clipboard.writeText(gluserId);
      toast.success(`GluserId copied to clipboard!`, {
        autoClose: 2000,
      });
    } else {
      toast.error("No gluserId available to copy.");
    }
  };
  const handleFilter = () => {
    const selectedRange = dateRange[0];
    const startDate = selectedRange.startDate || new Date();
    const endDate = selectedRange.endDate || new Date();
    setShowModal(false);
    loadLogs(startDate, endDate);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const handleSearch = () => {
    if (error) {
      toast.error("Please enter a valid email address");
      return;
    }
    setCurrentPage(1);
    loadLogs(null, null, searchTerm);
  };
    const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value && !validateEmail(value)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loading} />
      <CustomLoader isLoading={loading} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">User Logs</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div mt-4">
              <div className="flex-div-sm">
                <h1 className="Activityhistory">User Logs</h1>

                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <div className="Date-Range">
                        <Button
                          className="reg-btn"
                          onClick={() => setShowModal(true)}
                          style={{ marginRight: "10px" }}
                        >
                          <i className="fa fa-plus"></i>Select Date Range
                        </Button>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault(); // Prevent form default submission
                            handleSearch(); // Trigger the search functionality
                          }}
                        >
                          <div>
                            <Form.Group controlId="formBasicEmail">
                              <div className="input-group">
                               <Form.Control
                                                                 type="search"
                                                                 placeholder="Enter Email"
                                                                 className="form-control"
                                                                 value={searchTerm}
                                                                 onChange={handleInputChange}
                                                               />
                                <div className="input-group-append">
                                  <Button
                                    className="reg-btn"
                                    type="submit" // Submit the form on button click
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </Form>
                      </div>
                    {error && <Form.Text className="text-danger">{error}</Form.Text>}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>User Name / GluserId</th>
                        <th>User Email</th>
                        <th>Company Name</th>
                        <th>Actions</th>
                        <th>Details</th>
                        <th>Amount</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs?.map((log) => (
                        <tr key={log._id}>
                          <td style={{ cursor: "pointer" }}>
                            {log?.userName || "N/A"}
                            <img
                              onClick={() => handleCopyGluserId(log.gluserId)}
                              className="AmmountLogo"
                              src={CopyIcon}
                              alt=""
                            />
                          </td>
                          <td>{log?.userEmail || "N/A"}</td>
                          <td>
                            {CompanyLocation[log?.companyId]
                              ? `${CompanyLocation[log?.companyId]}-  ${
                                  log?.companyId
                                }`
                              : log?.companyId || "N/A"}
                          </td>
                          <td>{log?.operation || "N/A"}</td>
                          <td>{log?.Details || "N/A"}</td>
                          <td>
                            {log?.amount || "N/A"}
                            <img
                              className="AmmountLogo"
                              src={coins}
                              alt="tarck"
                            />
                          </td>
                          <td>
                            {log.createdAt
                              ? moment(log.createdAt).format(
                                  "YYYY-MM-DD hh:mm A"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="full-div text-center pagination-container">
                {totalItems === 0 ? (
                  <p>No logs available.</p>
                ) : (
                  <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    customClassNames={{
                      rpbItemClassName: "custom-item",
                      rpbItemClassNameActive: "custom-item--active",
                      rpbGoItemClassName: "custom-go-item",
                      rpbItemClassNameDisable: "custom-item--disable",
                      rpbProgressClassName: "custom-progress-bar",
                      rpbRootClassName: "custom-root",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Button
              closeButton
              className="close-btn"
              onClick={handleCloseModal}
            >
              <i className="fa fa-close"></i>
            </Button>

            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-picker-container">
              <DateRange
                ranges={dateRange} // Pass the ARRAY
                onChange={(item) => setDateRange([item.selection])} // Correct update: NEW ARRAY
                locale={enUS}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};

export default UserPoints;
