import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Form, Modal, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";

import { Pagination } from "react-pagination-bar";

import {  handleGetErrorTracking, handleGetSignupTracking, } from "../shared/api";
import moment from "moment";
import { API_URL, ERROR_LOGS_LIMIT } from "../constant";
import { Searchbar } from "../Components/Searchbar";
import PaginationComponent from "./PaginationComponent";


const SignupTracking = () => {
 const [trackingData, setTrackingData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setloader] = useState(true);
  const [errorLogs, setErrorLogs] = useState([]);
  const [totalItemspage, setTotalItemspage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(''); // Single input for any search term

 
    



  useEffect(() => {
    fetchTrackingData();
 
  }, []);

  useEffect(()=>{
    fetchTrackingError()
  },[currentPage])
  /** 
  The fetchTrackingData function asynchronously retrieves signup sources and signup errors. 
 It updates the state with the data if successful or sets an error message if either fetch fails.
 */
  const fetchTrackingData = () => {
    setloader(true);
    handleGetSignupTracking()
      .then((res) => {
        setTrackingData(res?.data?.counts);
        setTotalItems(res?.data?.totalUsers)
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };

/**
 * Fetches error logs with pagination, updating error logs and total items in state; sets loading state during fetch.
 */
  const fetchTrackingError = () => {
    setloader(true);
    handleGetErrorTracking(currentPage,ERROR_LOGS_LIMIT,'')
      .then((res) => {
        setErrorLogs(res?.data?.decryptedErrorLogs)
        setTotalItemspage(res?.data?.totalItems)
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };
  /**
 * Fetches error logs based on the current page and search term, updating `errorLogs` and `totalItems`. 
 * Shows a loader during the fetch and hides it upon completion or error.
 */

  const handleSearch = async () => {
    setloader(true);
    try {
      // Pass searchTerm as a single parameter to the backend
     const  response = await handleGetErrorTracking(currentPage,ERROR_LOGS_LIMIT,searchTerm)

      setErrorLogs(response.data.decryptedErrorLogs);
      setTotalItemspage(response.data.totalItems);
      setloader(false);
    } catch (error) {
      setloader(false)
    }
  };

  const truncateText = (text, maxLength = 50) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSearch();
    }
};
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Signup Tracking</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>
           
            <div className="full-div">
              <div className="flex-div-sm">
               
              <h1 >Signup Tracking   <span
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    marginLeft: "5px",
                  }}
                >
                  {totalItems} Users
                </span></h1>
              

             
                <ul className="btn-lister">
                <li>


</li>

                  
                   
                  
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                    <tr>
                        <th>Source</th>
                        <th>Users Count</th>
                    </tr>
                    </thead>

                    {!loader ? (
                      trackingData?.length > 0 ? (
                        <tbody>
                          {trackingData?.map((record,index) => (
                            <tr key={index}>
                              <td>{record?.source}</td>
                              <td>{record?.count}</td>
                          

                             
                              
                             
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody
                          className="norecords">
                        
                          <tr>
                            <td colSpan="5">No Record Found</td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <CustomLoader isLoading={loader} />
                    )}
                  </Table>
                </div>
              </div>

            </div>
            <div className="full-div mt-4">
              <div className="flex-div-sm">
               
              <h1 >Error Logs   <span
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    marginLeft: "5px",
                  }}
                >
                  {totalItemspage} Users
                </span></h1>
              

             
                <ul className="btn-lister">
                  <li>
                  <div className="search-pnl">

<Form
  onSubmit={(e) => {
    e.preventDefault();
  }}
>
  
  <Form.Group controlId="formBasicEmail">
            <div className="input-group">
                <Form.Control
                     onChange={(e) => setSearchTerm(e.target.value)}
                    type="search"
                    placeholder="Search by name, email,phone"
                    className="form-control"
                    value={searchTerm}
                    onKeyPress={handleKeyPress}
                />
                <div className="input-group-append">
                    <Button className="reg-btn" onClick={handleSearch}>
                        <i className="fa fa-search"></i>
                    </Button>
                </div>
            </div>
        </Form.Group>
</Form>
</div>
                  </li>

                  
                   
                  
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                    <tr>
                    <th>Email</th>
            <th>Name</th>
            <th>Phone</th>
                <th>Timestamp</th>
                <th>Message</th>
             
            <th>Source Type</th>          
                </tr>
                    </thead>

                    {!loader ? (
                      errorLogs?.length > 0 ? (
                        <tbody>
                          {errorLogs?.map((log,index) => (
                            <tr key={index}>
   <td>{log.userDetails?.email || 'N/A'}</td>
                  <td>{log.userDetails?.name || 'N/A'}</td>
                  <td>{log.userDetails?.phone || 'N/A'}</td>

                  <td>{log.createdAt ? moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</td>

                    <td>{log.errorMessage}</td>
                    {/* <td>{truncateText(log.stackTrace)}</td> */}
                 
                  <td>{log.userDetails?.source || 'N/A'}</td>

                             
                              
                             
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody
                          className="norecords">
                        
                          <tr>
                            <td colSpan="5">No Record Found</td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <CustomLoader isLoading={loader} />
                    )}
                  </Table>
                </div>
              </div>

              <div
                className="full-div text-center pagination-container"
              >
                <PaginationComponent
                  totalItems={totalItemspage}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
               
              </div>
            </div>
          </div>
        </div>
      




       

      </main>
    </>
  );
};

export default SignupTracking;


